import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./styled.css";
// componentes
import Form from "./form";
import CardQuestionAnswered from "./cardQuestionAnswered";
// service
import { verifySurveyByUser } from "../../services/survey";

export default function Home() {
  // parametros
  const [searchParams] = useSearchParams();
  // usuário
  const [user, setUser] = useState(null);
  // carregamento
  const [loadingPage, setLoadingPage] = useState(true);
  // step
  const [step, setStep] = useState(null);

  function getUserData() {
    verifySurveyByUser({
      eventId: user.idEvent,
      loginId: user.idPart,
      question: "1. Selecione o(s) tipo(s) de transporte que utilizou para se deslocar até o evento.",
    })
      .then((res) => {
        console.log("ok", res);

        if (res.message === "QUESTION_ANSWERED") {
          setStep(2);
        } else if (res.message === "QUESTION_AVAILABLE") {
          setStep(1);
        } else {
        }

        setLoadingPage(false);
      })
      .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    if (window.location.search !== "") {
      const idPart = searchParams.get("idPart");
      const idEvent = searchParams.get("idEvent");

      setUser({ idPart, idEvent });
    }
  }, [searchParams]);

  useEffect(() => {
    if (user) getUserData();
  }, [user]);

  return (
    <>
      <section className="section-vibra">
        {loadingPage ? (
          <a className="a-loading" style={{ margin: "auto" }} />
        ) : (
          <>
            {step === 1 && <Form user={user} />}
            {step === 2 && <CardQuestionAnswered />}
          </>
        )}
      </section>
    </>
  );
}
