import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Label,
  Button,
} from "reactstrap";
// service
import { postSurvey } from "../../services/survey";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";

export default function Form(props) {
  const {user} = props;
  //   alertas
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState(false);
  // carregamento
  const [loading, setLoading] = useState(false);
  // primeira questão
  const [question1, setQuestion1] = useState("");

  async function verifyForm(e) {
    setLoading(true);

    if (!question1) {
      setErrors("A questão 1 é obrigatória");
      setLoading(false);
      return;
    } else if (!e.target[10].value) {
      setErrors("A questão 4 é obrigatória");
      setLoading(false);
      return;
    } else if (!e.target[11].value) {
      setErrors("A questão 5 é obrigatória");
      setLoading(false);
      return;
    }

    await sendData(
      "1. Selecione o(s) tipo(s) de transporte que utilizou para se deslocar até o evento.",
      question1
    );
    await sendData(
      "2. Se você veio de carro, qual foi o combustível utilizado? Caso não tenha certeza, basta deixar essa pergunta em branco. Vamos considerar o cenário de maior emissão.",
      e.target[8].value
    );
    await sendData(
      "3. Realizou uma viagem aérea? No caso, insira origem-destino entre aeroportos.",
      e.target[9].value
    );
    await sendData(
      "4. Qual a distância aproximada que percorreu até o evento? (uma estimativa em km da parte terrestre)",
      e.target[10].value
    );
    await sendData(
      "5. Se você não tem ideia da distância em km para chegar ao evento de hoje, por favor insira abaixo de qual cidade e nome do bairro do qual você veio.",
      e.target[11].value,
      "last"
    );
  }

  async function sendData(question, answer, last) {
    const data = {
      eventId: user.idEvent,
      loginId: user.idPart,
      question: question,
      answer: answer,
      hit: true,
    };

    await postSurvey(data).then((res) => {
      console.log("ok", res);
      if (last) {
        setSuccess(true);
      }
    });
  }

  return (
    <>
      {errors && (
        <SweetAlert
          warning
          title="Alerta"
          onConfirm={() => {
            setErrors("");
          }}
        >
          {errors}
        </SweetAlert>
      )}

      {success && (
        <SweetAlert
          success
          title="Sucesso"
          onConfirm={() => window.location.reload()}
        >
          Formulário enviado com sucesso!
        </SweetAlert>
      )}

      <Card className={"card-vibra"}>
        <CardBody>
          <CardTitle>Carbono Zero</CardTitle>
          <p>
            Topa nos ajudar a neutralizar as emissões de carbono geradas em seu
            deslocamento?
          </p>

          <p>
            Vamos tornar esse evento carbono neutro e, para isso, precisamos da
            sua ajuda! Responda às perguntas abaixo com informações sobre o seu
            deslocamento até aqui e, com esses dados, poderemos neutralizar a
            sua pegada de carbono também.{" "}
          </p>

          <p>
            Se você veio de outra cidade, selecione os meios de transporte
            utilizados, como: avião e carro. No caso de transporte aéreo, insira
            o aeroporto de destino na pergunta 3.{" "}
          </p>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              verifyForm(e);
            }}
          >
            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <Label className="form-label">
                  1. Selecione o(s) tipo(s) de transporte que utilizou para se
                  deslocar até o evento.*
                </Label>

                <div className="form-check mb-3">
                  <Input
                    type="radio"
                    name="packaging"
                    id="transp1"
                    onClick={() => setQuestion1("Carro")}
                  />
                  <label className="form-check-label" htmlFor="transp1">
                    Carro
                  </label>
                </div>

                <div className="form-check mb-3">
                  <Input
                    type="radio"
                    name="packaging"
                    id="transp2"
                    onClick={() =>
                      setQuestion1(
                        "Carro (de carona com mais alguém que também veio para o evento)"
                      )
                    }
                  />
                  <label className="form-check-label" htmlFor="transp2">
                    Carro (de carona com mais alguém que também veio para o
                    evento)
                  </label>
                </div>

                <div className="form-check mb-3">
                  <Input
                    type="radio"
                    name="packaging"
                    id="transp3"
                    onClick={() => setQuestion1("Ônibus")}
                  />
                  <label className="form-check-label" htmlFor="transp3">
                    Ônibus
                  </label>
                </div>

                <div className="form-check mb-3">
                  <Input
                    type="radio"
                    name="packaging"
                    id="transp4"
                    onClick={() => setQuestion1("Bicicleta")}
                  />
                  <label className="form-check-label" htmlFor="transp4">
                    Bicicleta
                  </label>
                </div>

                <div className="form-check mb-3">
                  <Input
                    type="radio"
                    name="packaging"
                    id="transp5"
                    onClick={() => setQuestion1("A pé")}
                  />
                  <label className="form-check-label" htmlFor="transp5">
                    A pé
                  </label>
                </div>

                <div className="form-check mb-3">
                  <Input
                    type="radio"
                    name="packaging"
                    id="transp6"
                    onClick={() => setQuestion1("Moto")}
                  />
                  <label className="form-check-label" htmlFor="transp6">
                    Moto
                  </label>
                </div>

                <div className="form-check mb-3">
                  <Input
                    type="radio"
                    name="packaging"
                    id="transp7"
                    onClick={() => setQuestion1("Avião")}
                  />
                  <label className="form-check-label" htmlFor="transp7">
                    Avião
                  </label>
                </div>

                <div className="form-check mb-3">
                  <Input
                    type="radio"
                    name="packaging"
                    id="transp9"
                    onClick={() => setQuestion1("Trem / Metrô")}
                  />
                  <label className="form-check-label" htmlFor="transp9">
                    Trem / Metrô
                  </label>
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col md={12}>
                <Label className="form-label">
                  2. Se você veio de carro, qual foi o combustível utilizado?
                  Caso não tenha certeza, basta deixar essa pergunta em branco.
                  Vamos considerar o cenário de maior emissão.
                </Label>

                <Input className="form-control" type="text" />
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col md={12}>
                <Label className="form-label">
                  3. Realizou uma viagem aérea? No caso, insira origem-destino
                  entre aeroportos.
                </Label>

                <Input className="form-control" type="text" />
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col md={12}>
                <Label className="form-label">
                  4. Qual a distância aproximada que percorreu até o evento?
                  (uma estimativa em km da parte terrestre)*
                </Label>

                <Input className="form-control" type="text" />
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col md={12}>
                <Label className="form-label">
                  5. Se você não tem ideia da distância em km para chegar ao
                  evento de hoje, por favor insira abaixo de qual cidade e nome
                  do bairro do qual você veio.*
                </Label>

                <Input className="form-control" type="text" />
              </Col>
            </Row>

            <Row style={{ marginTop: 30 }}>
              <Col
                md={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  color="success"
                  type="submit"
                  disabled={loading}
                  style={{ width: 140 }}
                >
                  {loading ? "Carregando" : "Salvar"}
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </>
  );
}
