import React from "react";
import { Card, CardBody } from "reactstrap";

export default function CardQuestionAnswered() {
  return (
    <Card style={{ width: "90%", height: 200, margin: "auto" }}>
      <CardBody style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ textAlign: "center" }}>
          <h2>Parabéns!</h2>
          <a>Você já respondeu a enquete.</a>
          <i
            className="mdi mdi-comment-check-outline"
            style={{ color: "green", fontSize: 20 }}
          />
        </div>
      </CardBody>
    </Card>
  );
}
