//styles
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/theme.scss";
//rotas
import { BrowserRouter, Routes, Route } from "react-router-dom";
//pages
import AcheTransporte from "./pages/ache/enquete1";
import AvipamEnquete1 from "./pages/avipam/enquete1";
import AvipamEnquete2 from "./pages/avipam/enquete2";
import AvipamEnquete3 from "./pages/avipam/enquete3";
import AvipamEnquete4 from "./pages/avipam/enquete4";
import AvipamEnquete5 from "./pages/avipam/enquete5";
import AvipamEnquete6 from "./pages/avipam/enquete6";
import AvipamEnquete7 from "./pages/avipam/enquete7";
import IfoodEnquete1 from "./pages/ifood/enquete1";
import IfoodEnquete2 from "./pages/ifood/enquete2";
import IfoodEnquete3 from "./pages/ifood/enquete3";
import IfoodEnquete4 from "./pages/ifood/enquete4";
import IfoodPesquisa1 from "./pages/ifood/pesquisa1";
import StefaniniEnquete1 from "./pages/stefanini/enquete1";
import StefaniniEnquete2 from "./pages/stefanini/enquete2";
import Boehringer1 from "./pages/boehringer";
import BoehringerQuiz1 from "./pages/boehringer/quiz1";
import BoehringerQuiz2 from "./pages/boehringer/quiz2";
import BoehringerQuiz3 from "./pages/boehringer/quiz3";
import BoehringerQuiz4 from "./pages/boehringer/quiz4";
import BoehringerQuiz5 from "./pages/boehringer/quiz5";
import BoehringerQuiz6 from "./pages/boehringer/quiz6";
import Petropolis from "./pages/petropolis/day1";
import Petropolis1PDV1 from "./pages/petropolis/day1/pdv1";
import Petropolis1PDV2 from "./pages/petropolis/day1/pdv2";
import Petropolis1PDV3 from "./pages/petropolis/day1/pdv3";
import Petropolis1PDV4 from "./pages/petropolis/day1/pdv4";
import Petropolis1PDV5 from "./pages/petropolis/day1/pdv5";
import Petropolis1PDV6 from "./pages/petropolis/day1/pdv6";
import Petropolis2 from "./pages/petropolis/day2";
import Petropolis2PDV1 from "./pages/petropolis/day2/pdv1";
import Petropolis2PDV2 from "./pages/petropolis/day2/pdv2";
import Petropolis2PDV3 from "./pages/petropolis/day2/pdv3";
import Petropolis2PDV4 from "./pages/petropolis/day2/pdv4";
import Petropolis2PDV5 from "./pages/petropolis/day2/pdv5";
import Petropolis2PDV6 from "./pages/petropolis/day2/pdv6";
import Neogen from "./pages/neogen";
import Kenvue from "./pages/kenvue";
import Profuse from "./pages/ache/profuse";
import Vibra from "./pages/vibra"
// provider
import QuizProvider from "./assets/context/index";

function App() {
  return (
    <div>
      <BrowserRouter>
        <QuizProvider>
          <Routes>
            <Route path="/ache/transporte" element={<AcheTransporte />} />
            <Route path="/avipam/enquete1" element={<AvipamEnquete1 />} />
            <Route path="/avipam/enquete2" element={<AvipamEnquete2 />} />
            <Route path="/avipam/enquete3" element={<AvipamEnquete3 />} />
            <Route path="/avipam/enquete4" element={<AvipamEnquete4 />} />
            <Route path="/avipam/enquete5" element={<AvipamEnquete5 />} />
            <Route path="/avipam/enquete6" element={<AvipamEnquete6 />} />
            <Route path="/avipam/enquete7" element={<AvipamEnquete7 />} />
            <Route path="/ifood/enquete1" element={<IfoodEnquete1 />} />
            <Route path="/ifood/enquete2" element={<IfoodEnquete2 />} />
            <Route path="/ifood/enquete3" element={<IfoodEnquete3 />} />
            <Route path="/ifood/enquete4" element={<IfoodEnquete4 />} />
            <Route path="/ifood/enquete4" element={<IfoodEnquete4 />} />
            <Route path="/ifood/pesquisa1" element={<IfoodPesquisa1 />} />
            <Route path="/stefanini/quiz1" element={<StefaniniEnquete1 />} />
            <Route path="/stefanini/quiz2" element={<StefaniniEnquete2 />} />
            <Route path="/boehringer/passeio1" element={<Boehringer1 />} />
            <Route path="/boehringer/quiz1" element={<BoehringerQuiz1 />} />
            <Route path="/boehringer/quiz2" element={<BoehringerQuiz2 />} />
            <Route path="/boehringer/quiz3" element={<BoehringerQuiz3 />} />
            <Route path="/boehringer/quiz4" element={<BoehringerQuiz4 />} />
            <Route path="/boehringer/quiz5" element={<BoehringerQuiz5 />} />
            <Route path="/boehringer/quiz6" element={<BoehringerQuiz6 />} />
            <Route path="/petropolis1" element={<Petropolis />} />
            <Route path="/petropolis1/pdv1" element={<Petropolis1PDV1 />} />
            <Route path="/petropolis1/pdv2" element={<Petropolis1PDV2 />} />
            <Route path="/petropolis1/pdv3" element={<Petropolis1PDV3 />} />
            <Route path="/petropolis1/pdv4" element={<Petropolis1PDV4 />} />
            <Route path="/petropolis1/pdv5" element={<Petropolis1PDV5 />} />
            <Route path="/petropolis1/pdv6" element={<Petropolis1PDV6 />} />
            <Route path="/petropolis2" element={<Petropolis2 />} />
            <Route path="/petropolis2/pdv1" element={<Petropolis2PDV1 />} />
            <Route path="/petropolis2/pdv2" element={<Petropolis2PDV2 />} />
            <Route path="/petropolis2/pdv3" element={<Petropolis2PDV3 />} />
            <Route path="/petropolis2/pdv4" element={<Petropolis2PDV4 />} />
            <Route path="/petropolis2/pdv5" element={<Petropolis2PDV5 />} />
            <Route path="/petropolis2/pdv6" element={<Petropolis2PDV6 />} />
            <Route path="/forumneogen" element={<Neogen />} />
            <Route path="/kenvue/quiz1" element={<Kenvue />} />
            <Route path="/profuse" element={<Profuse />} />
            <Route path="/vibracarbono" element={<Vibra />} />
          </Routes>
        </QuizProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
