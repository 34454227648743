import React, { useEffect, useState } from "react";
import { products } from "./products";
// gerador de imagem
import html2canvas from "html2canvas";
// componentes
import Product from "./product";

export default function PageProducts(props) {
  const { allAnswer, user } = props;
  const [productList, setProductList] = useState([]);
  // imagem para baixar
  const [img, setImg] = useState(null);
  // step 1
  const [step1, setStep1] = useState([]);

  const data = {
    pele: ["OLEOSA"],
    preocupacao: ["CONTROLE DA OLEOSIDADE"],
    estetico: ["SIM"],
    condicoes: ["DERMATITE ATÓPICA", "DIABETES"],
    capilar: ["NAO"],
    maquiagem: ["NAO"],
  };

  const dataQuestion = allAnswer;

  function getImage() {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      setImg(canvas.toDataURL("image/jpeg"));
    });
  }

  function getProducts() {
    var productsSelecteds = [];

    products.map((e, i) => {
      var addProduct = true;
      var addProductOr = false;

      for (const [keyProduct, valueProduct] of Object.entries(e)) {
        for (const [key, value] of Object.entries(dataQuestion)) {
          if (keyProduct === key) {
            if (e.type === "or") {
              var search = null;

              if (keyProduct === "pele") {
                search = valueProduct.find((prd) => prd === value[0]);
              } else {
                search = value.find(
                  (prod) => prod === valueProduct || valueProduct === "TODOS"
                );
              }

              if (search) addProductOr = true;
            } else {
              var search = value.find(
                (prod) => prod === valueProduct || valueProduct === "TODOS"
              );
              if (e.type && e.type === "mandatoryNecessity") {
                var seachMandatory = dataQuestion.preocupacao.find(
                  (prod) => prod === e.preocupacao
                );

                if (seachMandatory && search) {
                  addProductOr = true;
                }
              } else if (e.type && e.type === "or" && search) {
                addProductOr = true;
              } else if (!e.type && !search) {
                addProduct = false;
              } else {
              }
            }
          }
        }
      }

      if (
        (addProduct && !e.type) ||
        (addProductOr &&
          e.type &&
          (e.type === "or" ||
            e.type === "mandatoryNecessity" ||
            e.type === "doubleField"))
      ) {
        productsSelecteds.push(e);
      }
    });

    setProductList(productsSelecteds);
  }

  async function downloadFile() {
    if (user?.idEvent) {
      window.postMessage(
        JSON.stringify({
          message: "base64ToDownload",
          data: img,
          name: "rotinaProfuse.jpeg",
        }),
        "*"
      );
      window.parent.ReactNativeWebView.postMessage(
        JSON.stringify({
          message: "base64ToDownload",
          data: img,
          name: "rotinaProfuse.jpeg",
        })
      );
    } else {
      const blob = await fetch(img).then((res) => res.blob());

      const arquivo = {
        title: "",
        files: [new File([blob], "imagem.jpg", { type: "image/jpeg" })],
      };
      await navigator.share(arquivo);
    }
  }

  function getMessage() {
    if (
      dataQuestion.pele.find((e) => e === "OLEOSA" || e === "MISTA") &&
      dataQuestion.estetico.find((e) => e === "SIM")
    ) {
      return true;
    } else return false;
  }

  async function showProductsNutrel() {
    var productsData = productList.filter((e) => e.step === 1);

    var dataList = [];

    const ruleShow = await getMessage();

    if (productsData.length > 0) {
      productsData.map((e) => {
        if (!ruleShow || !e.ruleShow) {
          dataList.push(e);
        }
      });
    }

    setStep1(dataList);
  }

  useEffect(() => {
    if (productList.length > 0) showProductsNutrel();
  }, [productList]);

  useEffect(() => {
    if (
      dataQuestion?.capilar &&
      dataQuestion?.condicoes &&
      dataQuestion?.estetico &&
      dataQuestion?.maquiagem &&
      dataQuestion?.pele &&
      dataQuestion?.preocupacao
    )
      getProducts();
  }, [dataQuestion]);

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("capture")) {
        getImage();
      }
    }, 500);
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        id="capture"
        className="div-profuse-card"
        style={{
          padding: "40px 20px",
          textAlign: "center",
        }}
      >
        <h3 style={{ marginBottom: 20 }}>
          {user?.name ? `${user.name},` : ""} Conheça sua rotina
        </h3>
        {productList.length > 0 ? (
          <>
            {step1.length > 0 && (
              <>
                <h4 style={{ textAlign: "center" }}>LIMPEZA</h4>
                {step1.map((e, i) => (
                  <div key={i} style={{ width: "100%" }}>
                    <Product data={e} />
                  </div>
                ))}
              </>
            )}

            {productList.filter((e) => e.step === 2).length > 0 && (
              <>
                <h4 style={{ textAlign: "center", marginTop: 20 }}>
                  HIDRATAÇÃO
                </h4>
                {productList
                  .filter((e) => e.step === 2)
                  .map((e, i) => (
                    <div key={i} style={{ width: "100%" }}>
                      <Product data={e} />
                    </div>
                  ))}
              </>
            )}

            {productList.filter((e) => e.step === 3).length > 0 && (
              <>
                <h4 style={{ textAlign: "center", marginTop: 20 }}>
                  REPARAÇÃO
                </h4>
                {productList
                  .filter((e) => e.step === 3)
                  .map((e, i) => (
                    <div key={i} style={{ width: "100%" }}>
                      <Product data={e} />
                    </div>
                  ))}
              </>
            )}

            {productList.filter((e) => e.step === 4).length > 0 && (
              <>
                <h4 style={{ textAlign: "center", marginTop: 20 }}>
                  TRATAMENTO
                </h4>
                {productList
                  .filter((e) => e.step === 4)
                  .map((e, i) => (
                    <div key={i} style={{ width: "100%" }}>
                      <Product data={e} />
                    </div>
                  ))}
              </>
            )}

            {productList.filter((e) => e.step === 5).length > 0 && (
              <>
                <h4 style={{ textAlign: "center", marginTop: 20 }}>
                  PROTEÇÃO SOLAR
                </h4>
                {productList
                  .filter((e) => e.step === 5)
                  .map((e, i) => (
                    <div key={i} style={{ width: "100%" }}>
                      <Product data={e} />
                    </div>
                  ))}
              </>
            )}

            {getMessage() && (
              <a style={{ marginTop: 20, color: "red" }}>
                <span style={{ fontWeight: "bold" }}>Importante:</span> Ao
                realizar procedimentos, prefira a linha Nutrel até que sua pele
                se recupere e volte a rotina sugerida pelo seu médico
              </a>
            )}
          </>
        ) : (
          <a>Nenhum produto para listar</a>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: 20,
        }}
      >
        <a
          className="a-profuse-btn-action"
          style={{ backgroundColor: "#645268", fontSize: 15 }}
          onClick={() => window.location.reload()}
        >
          Responder Novamente
        </a>

        <a
          className="a-profuse-btn-action"
          style={{ backgroundColor: "#645268", fontSize: 15 }}
          onClick={() => downloadFile()}
        >
          Compartilhar Resultado
        </a>
      </div>
    </div>
  );
}
