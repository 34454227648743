import ClareadorOlhos from "../../../assets/images/profuse/produtos/CLAREADOR-OLHOS.jpg";
import ClareadorConcentrado from "../../../assets/images/profuse/produtos/CLAREADOR-CONCENTRADO.jpg";
import ClareadorCisteamina from "../../../assets/images/profuse/produtos/CLAREADOR-CISTEAMINA.jpg";
import AntissinaisProColageno from "../../../assets/images/profuse/produtos/ANTISSINAIS-PRÓ-COLAGENO.jpg";
import ProtetorPoCompacto from "../../../assets/images/profuse/produtos/PROTETOR-SOLAR-PÓ-COMPACTO.jpg";
import ProtetorAntiOx from "../../../assets/images/profuse/produtos/PROTETOR-SOLAR-ANTI-OX.jpg";
import AntissinaisC20 from "../../../assets/images/profuse/produtos/ANTISSINAIS-VIT-C-20.jpg";
import Hyalu8D from "../../../assets/images/profuse/produtos/8D-HYALU+.jpg";
import NutrelCalm from "../../../assets/images/profuse/produtos/NUTREL-CALMENTE--AI.jpg";
import NutrelGelBanho from "../../../assets/images/profuse/produtos/NUTREL-GEL-DE-BANHO.jpg";
import NutrelIntensivo from "../../../assets/images/profuse/produtos/NUTREL-INTENSIVO.jpg";
import NutrelB5 from "../../../assets/images/profuse/produtos/NUTREL-MULTIRREPARADOR-B5.jpg";
import NutrelMicelar from "../../../assets/images/profuse/produtos/NUTREL-SOLUÇÃO-MICELAR.jpg";
import NutrelSaboneteLiquido from "../../../assets/images/profuse/produtos/NUTREL-SABONETE-LÍQUIDO-FACIAL.jpg";
import NutrelBalm from "../../../assets/images/profuse/produtos/NUTREL-BALM.jpg";
import NutrelSaboneteBarra from "../../../assets/images/profuse/produtos/NUTREL-SABONETE-BARRA.jpg";
import NutrelLabial from "../../../assets/images/profuse/produtos/NUTREL-HIDRATANTE-REPARADOR-LABIAL.jpg";
import ControleOliosidadeLiquido from "../../../assets/images/profuse/produtos/CONTROLE-DA-OLEOSIDADE-SABONETE-LÍQUIDO.jpg";
import ControleOliosidadeCreme from "../../../assets/images/profuse/produtos/CONTROLE-DA-OLEOSIDADE-CREME-HIDRATANTE.jpg";
import ControleOliosidadeBarra from "../../../assets/images/profuse/produtos/CONTROLE-DA-OLEOSIDADE-SABONETE-BARRA.jpg";
import Ultralnail from "../../../assets/images/profuse/produtos/ULTRALNAIL.jpg";
import CuidadoUnha from "../../../assets/images/profuse/produtos/CUIDADO-CABELOS-E-UNHAS.jpg";
import Colageno from "../../../assets/images/profuse/produtos/COLÁGENO-ULTRA.jpg";
import SembleCollagen from "../../../assets/images/profuse/produtos/SEMBLÉ-COLLAGEN-ULTRA.jpg";
import SembleMax from "../../../assets/images/profuse/produtos/SEMBLÉ-FORT-MAX.jpg";

export const products = [
  {
    img: ClareadorOlhos,
    name: "CLAREADOR OLHOS",
    pele: "TODOS",
    preocupacao: "OLHEIRAS",
    step: 4,
  },
  {
    img: ClareadorConcentrado,
    name: "CLAREADOR CONCENTRADO",
    pele: "TODOS",
    preocupacao: "MANCHAS / MELASMA",
    step: 4,
  },
  {
    img: ClareadorCisteamina,
    name: "CLAREADOR CISTEAMINA",
    pele: "TODOS",
    preocupacao: "MANCHAS / MELASMA",
    step: 4,
  },
  {
    img: AntissinaisProColageno,
    name: "ANTISSINAIS PRÓ-COLAGENO",
    pele: "TODOS",
    preocupacao: "FLACIDEZ DA PELE",
    step: 4,
  },
  {
    img: ProtetorPoCompacto,
    name: "PROTETOR SOLAR PÓ COMPACTO",
    pele: "TODOS",
    maquiagem: "SIM",
    step: 5,
  },
  {
    img: ProtetorAntiOx,
    name: "PROTETOR SOLAR ANTI-OX",
    pele: "TODOS",
    step: 5,
  },
  {
    img: AntissinaisC20,
    name: "ANTISSINAIS VIT C-20",
    pele: "TODOS",
    preocupacao: "RUGAS E LINHAS FINAS",
    step: 4,
  },
  {
    img: Hyalu8D,
    name: "8D HYALU+",
    pele: "TODOS",
    preocupacao: "RUGAS E LINHAS FINAS",
    step: 2,
  },
  {
    img: NutrelCalm,
    name: "NUTREL CALMENTE -AI",
    // pele: "SENSÍVEL",
    // preocupacao: "CUIDADOS CORPORAIS",
    condicoes: "DERMATITE ATÓPICA",
    // type: "mandatoryNecessity",
    step: 2,
  },
  {
    img: NutrelGelBanho,
    name: "NUTREL GEL DE BANHO",
    pele: "TODOS",
    preocupacao: "CUIDADOS CORPORAIS",
    step: 1,
    ruleShow: true,
  },
  {
    img: NutrelIntensivo,
    name: "NUTREL INTENSIVO",
    // pele: "SECA",
    // preocupacao: "CUIDADOS CORPORAIS",
    condicoes: "DIABETES",
    // type: "mandatoryNecessity",
    step: 2,
  },
  {
    img: NutrelB5,
    name: "NUTREL MULTIRREPARADOR B5",
    pele: ["SENSÍVEL", "SECA"],
    estetico: "SIM",
    type: "or",
    step: 3,
  },
  {
    img: NutrelMicelar,
    name: "NUTREL SOLUÇÃO MICELAR",
    pele: "TODOS",
    maquiagem: "SIM",
    step: 1,
    ruleShow: true,
  },
  {
    img: NutrelSaboneteLiquido,
    name: "NUTREL SABONETE LÍQUIDO FACIAL",
    pele: ["SENSÍVEL", "SECA", "NORMAL"],
    estetico: "SIM",
    condicoes: "ROSÁCEA",
    type: "or",
    step: 1,
    ruleShow: true,
  },
  {
    img: NutrelBalm,
    name: "NUTREL BALM",
    pele: ["SENSÍVEL", "SECA", "NORMAL"],
    estetico: "SIM",
    condicoes: "ROSÁCEA",
    type: "or",
    step: 2,
  },
  {
    img: NutrelSaboneteBarra,
    name: "NUTREL SABONETE BARRA",
    pele: ["SENSÍVEL", "SECA", "NORMAL"],
    estetico: "SIM",
    preocupacao: "CUIDADOS CORPORAIS",
    condicoes: "ROSÁCEA",
    type: "mandatoryNecessity",
    step: 1,
    ruleShow: true,
  },
  {
    img: NutrelLabial,
    name: "NUTREL HIDRATANTE REPARADOR LABIAL",
    pele: ["SENSÍVEL", "SECA", "NORMAL"],
    estetico: "SIM",
    condicoes: "ROSÁCEA",
    type: "or",
    step: 2,
  },
  {
    img: ControleOliosidadeLiquido,
    name: "CONTROLE DA OLEOSIDADE SABONETE LÍQUIDO",
    pele: ["OLEOSA", "MISTA"],
    preocupacao: "CONTROLE DA OLEOSIDADE",
    type: "or",
    step: 1,
  },
  {
    img: ControleOliosidadeCreme,
    name: "CONTROLE DA OLEOSIDADE CREME HIDRATANTE",
    pele: ["OLEOSA", "MISTA"],
    preocupacao: "CONTROLE DA OLEOSIDADE",
    type: "or",
    step: 2,
  },
  {
    img: ControleOliosidadeBarra,
    name: "CONTROLE DA OLEOSIDADE SABONETE BARRA",
    pele: ["OLEOSA", "MISTA"],
    preocupacao: "CONTROLE DA OLEOSIDADE",
    type: "or",
    step: 1,
  },
  {
    img: Ultralnail,
    name: "UNTRALNAIL",
    pele: "TODOS",
    capilar: "SIM",
    step: 4,
  },
  {
    img: SembleMax,
    name: "SEMBLÉ FORT MAX",
    pele: "TODOS",
    capilar: "SIM",
    step: 4,
  },
  {
    img: SembleCollagen,
    name: "SEMBLÉ COLLAGEN ULTRA",
    pele: "TODOS",
    preocupacao: "FLACIDEZ DA PELE",
    step: 4,
  },
];
